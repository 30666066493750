import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { UserPageWrapper } from "./components/layout/UserPageWrapper";
import { SelectPortal } from "./pages/SelectPortal";
import { Login } from "./pages/Login";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { DashboardWrapper } from "./components/layout/DashboardWrapper";
import { Pickup } from "./pages/Pickup";
import { Customer } from "./pages/Customer";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<UserPageWrapper />}>
          <Route
            index
            element={
              <>
                <AuthenticatedTemplate>
                  <SelectPortal />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Login />
                </UnauthenticatedTemplate>
              </>
            }
          />
        </Route>
        <Route
          element={
            <>
              <AuthenticatedTemplate>
                <DashboardWrapper />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Navigate to="/" />
              </UnauthenticatedTemplate>
            </>
          }
        >
          <Route
            path="/pickup"
            element={
              <>
                <Pickup />
              </>
            }
          />
          <Route
            path="/customer"
            element={
              <>
                <Customer />
              </>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
