import { useMsal } from "@azure/msal-react";
import React from "react";

export const SignInButton = () => {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  return (
    <button
      className="bg-pelagia-dark text-white hover:opacity-75 py-4 w-1/2 rounded "
      onClick={initializeSignIn}
    >
      Log In
    </button>
  );
};
