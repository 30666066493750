import React, { useState } from "react";

interface ButtonProps {
  title: string;
  onClickRender?: () => void;
}

export const IframeButton = ({ title, onClickRender }: ButtonProps) => {
  const handleButtonClick = () => {
    if (typeof onClickRender === "function") onClickRender();
  };

  return (
    <div>
      <button
        className="bg-pelagia-dark text-white hover:opacity-75 py-4 w-1/2 h-20 border rounded-2xl flex justify-between p-7 items-center"
        onClick={handleButtonClick}
      >
        <span>{title}</span>
        <span>{"\u1433"}</span>
      </button>
    </div>
  );
};
