import { UserAvatar } from "../avatar/Avatar";
import { Outlet } from "react-router-dom";
import { ReactComponent as PelagiaLogo } from "../../assets/svg/pelagia-logo.svg";
import { DropdownBasicExample } from "../buttons/DropDownBtn";

export function DashboardWrapper() {
  return (
    <div className="bg-pelagia-extra-light min-h-screen">
      <div className="max-w-screen-[1400px] mx-auto sm:px-6 lg:px-8">
        <header>
          <nav className="py-4 bg-pelagia-extra-light">
            <div className="flex items-center justify-between">
              <a href="/" className="flex items-center">
                <PelagiaLogo className="h-8 sm:h-10 lg:h-12" />
              </a>
              <div className="flex items-center space-x-4">
                <DropdownBasicExample />
                <UserAvatar />
              </div>
            </div>
          </nav>
        </header>
        <div className="max-w-screen-xl mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
