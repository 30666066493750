import { Avatar } from "@fluentui/react-avatar";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Popover,
  PopoverSurface,
  PopoverTrigger,
} from "@fluentui/react-components";

export const UserAvatar = () => {
  const { instance, accounts } = useMsal();
  const account = accounts[0];

  const initializeSignOut = () => {
    instance.logoutRedirect();
  };

  return (
    <Popover positioning={"below-end"}>
      <PopoverTrigger>
        <button>
          <Avatar color="brand" size={36} className="cursor-pointer" />
        </button>
      </PopoverTrigger>

      <PopoverSurface>
        <div className="p-3 pl-1">
          <div>{account?.name}</div>
          <div className="">{account?.username}</div>
        </div>
        <div className="float-right">
          <button
            className="bg-pelagia-extra-light hover:bg-pelagia-light hover:text-white px-3 py-2 rounded-lg"
            onClick={() => initializeSignOut()}
          >
            Logout
          </button>
        </div>
      </PopoverSurface>
    </Popover>
  );
};
