import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import * as config from "../Config";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useId } from "@fluentui/react-components";
import useEmbedToken from "../components/auth/GetEmbedToken";
import { usePortals, Portals } from "../components/auth/usePortals";
import { PBIEmbed } from "../components/powerbi/PowerBiEmbed";

export function Customer() {
  const portals = usePortals();
  const { embedToken, getEmbedToken, idToken } = useEmbedToken(
    undefined,
    portals.customer.reportId,
    portals.customer.role
  );

  const [alternateUsernameInput, setAlternateUsernameInput] =
    useState<string>("");
  const { accounts } = useMsal();
  const inputId = useId("input");
  const user = accounts[0];
  const internalUser =
    user?.username?.includes("pelagia.com") ||
    user?.username?.includes("solv.as") ||
    user?.username?.includes("solv.no");

  return (
    <div className="flex flex-col">
      <div className="flex flex-col mb-4">
        <div className="flex justify-between items-center col-span-2">
          <p className="text-pelagia-primary text-4xl my-4 py-2">
            Hi, {user.name?.split(" ")[0]}!
          </p>

          {internalUser && (
            <div className="flex flex-col space-y-2 mt-2">
              <p>Import access from another user:</p>
              <div className="flex space-x-3">
                <div className="w-full">
                  <input
                    className="w-[250px] px-3 py-2 rounded-md"
                    id={inputId}
                    placeholder="ObjectID"
                    onChange={(e) => setAlternateUsernameInput(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    onClick={() =>
                      idToken &&
                      getEmbedToken(
                        idToken,
                        alternateUsernameInput,
                        portals.customer.reportId,
                        portals.customer.role
                      )
                    }
                    className="bg-pelagia-dark text-white hover:opacity-75 py-2 w-full rounded-lg px-3"
                  >
                    Get
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full h-full pb-8">
        {embedToken && (
          <PBIEmbed
            reportId={portals.customer.reportId || ""}
            embedToken={embedToken}
          />
        )}
      </div>
    </div>
  );
}
