import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";

const pca = new PublicClientApplication(msalConfig);

interface AuthProviderProps {
	children: React.ReactNode;
}

// Component
export const AuthProvider = ({ children }: AuthProviderProps) => {
	return <MsalProvider instance={pca}>{children}</MsalProvider>;
};
