import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import * as config from "../Config";
import { IframeButton } from "../components/buttons/IframeBtn";
import { useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useId } from "@fluentui/react-components";
import useEmbedToken from "../components/auth/GetEmbedToken";
import { usePortals } from "../components/auth/usePortals";
import { PBIEmbed } from "../components/powerbi/PowerBiEmbed";

export function Pickup() {
  const portals = usePortals();

  const { embedToken, getEmbedToken, idToken } = useEmbedToken(
    undefined,
    portals.pickup.reportId,
    portals.pickup.role
  );

  const [alternateUsernameInput, setAlternateUsernameInput] =
    useState<string>("");
  const { accounts } = useMsal();
  const inputId = useId("input");
  const user = accounts[0];

  const [showIframe, setShowIframe] = useState(false);

  const internalUser =
    user?.username?.includes("pelagia.com") ||
    user?.username?.includes("solv.as") ||
    user?.username?.includes("solv.no");

  const onClickIframe = () => {
    setShowIframe(true);
  };

  const onClickClose = () => {
    setShowIframe(false);
  };

  return (
    <div>
      {!showIframe ? (
        <div className="flex flex-col">
          <div className="flex flex-col mb-4">
            <div className="col-span-2">
              <p className="text-pelagia-primary text-4xl my-4 py-2">
                Hi, {user.name?.split(" ")[0]}!
              </p>
            </div>

            <div className="flex col-span-2 justify-between space-x-5 pb-2">
              <div className="w-1/2">
                <IframeButton
                  title="Request a new order"
                  onClickRender={onClickIframe}
                />
              </div>
              {internalUser && (
                <div className="flex flex-col space-y-2 mt-2">
                  <p>Import access from another user:</p>
                  <div className="flex space-x-3">
                    <div className="w-full">
                      <input
                        className="w-[250px] px-3 py-2 rounded-md"
                        id={inputId}
                        placeholder="ObjectID"
                        onChange={(e) =>
                          setAlternateUsernameInput(e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          idToken &&
                          getEmbedToken(
                            idToken,
                            alternateUsernameInput,
                            portals.pickup.reportId,
                            portals.pickup.role
                          )
                        }
                        className="bg-pelagia-dark text-white hover:opacity-75 py-2 w-full rounded-lg px-3"
                      >
                        Get
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full h-full pb-8">
            {embedToken && (
              <PBIEmbed
                reportId={portals.pickup.reportId || ""}
                embedToken={embedToken}
              />
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="col-span-2 mb-4">
            <p className="text-pelagia-primary text-4xl my-4 py-2">
              Hi, {user.name?.split(" ")[0]}!
            </p>
            <div className="w-1/2">
              <button
                className="min-w-fit w-2/5 bg-pelagia-dark text-white hover:opacity-75 py-4 h-20 border rounded-2xl flex p-7 items-center"
                onClick={onClickClose}
              >
                <span>{"\u1438"}</span>
                <span className="ml-12">Overview</span>
              </button>
            </div>
          </div>
          <iframe
            src="https://site-ttsci.powerappsportals.com/Account/Login/ExternalLogin?returnUrl=%2FOrders/new-order/?header=false%2F&provider=https%3A%2F%2Flogin.windows.net%2F774e4de4-4442-4fb3-b59f-c6a5a04defd2%2F"
            width="100%"
            height="800"
            title="Embedded Content"
          />
        </div>
      )}
    </div>
  );
}
