import { LinkButton } from "../components/buttons/LinkButton";
import { usePortals } from "../components/auth/usePortals";

export function SelectPortal() {
  const portals = usePortals();

  return (
    <>
      <div className="text-center">
        <p className="text-2xl text-white font-segoe m-10">
          Welcome to Pelagia AS partner portal<br></br>
        </p>
      </div>
      <div className="text-center">
        <p className="text-l text-white">Which portal do you want to access?</p>
      </div>
      <div className="my-10 grid grid-cols-1 gap-2">
        {Object.values(portals).map((i) => (
          <div className="item grid-span-1" key={i.title}>
            <LinkButton link={i.link}> {i.title} </LinkButton>
          </div>
        ))}
      </div>
    </>
  );
}
