import { Outlet } from "react-router-dom";
import { ReactComponent as PelagiaLogoWhite } from "../../assets/svg/pelagia-logo-white.svg";

export function UserPageWrapper() {
  return (
    <div className="bg-pelagia-primary h-screen flex justify-center items-center">
      <div className="flex justify-center items-center">
        <div className="bg-pelagia-light max-w-2/3 max-h-2/4 mx-20 rounded-3xl overflow-hidden">
          <div className="flex flex-col space-x-2 h-full mx-20">
            <div className="flex flex-col items-center mt-5">
              <PelagiaLogoWhite />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
