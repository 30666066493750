import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";

import { useLocation, useNavigate } from "react-router-dom";
import { usePortals, Portal, Portals } from "../auth/usePortals";

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const DropdownBasicExample = () => {
  const location = useLocation();
  const currentPortal = location.pathname.split("/")[1];

  const navigate = useNavigate();

  const portals: Portals = usePortals();

  return (
    <Menu>
      <MenuTrigger>
        <Button size="large">
          {capitalizeFirstLetter(currentPortal) || "Coose portal"}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            className="w-4 h-4 ml-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </Button>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {Object.values(portals).map((portal: Portal) => (
            <MenuItem key={portal.title} onClick={() => navigate(portal.link)}>
              {portal.title}
            </MenuItem>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
