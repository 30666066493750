import { useState, useEffect, useCallback } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

interface IReport {
  embedUrl: string;
  reportId: string;
  reportName: string;
}

export interface IEmbedToken {
  accessToken: string;
  reports: IReport[];
  expiry: string;
  status: number;
}

interface BodyData {
  oidInput?: string;
  reportId?: string;
  role?: string;
}

const useEmbedToken = (
  alternateOid?: string,
  reportId?: string,
  role?: string
) => {
  const [idToken, setIdToken] = useState<string | null>(null);
  const [embedToken, setEmbedToken] = useState<IEmbedToken | null>(null);
  const { instance } = useMsal();

  const getEmbedToken = useCallback(
    async (
      idToken: string,
      alternateOid?: string,
      reportId?: string,
      role?: string
    ) => {
      if (idToken) {
        try {
          const bodyData: BodyData = {};
          if (alternateOid) {
            bodyData.oidInput = alternateOid;
          }
          if (reportId) {
            bodyData.reportId = reportId;
          }
          if (role) {
            bodyData.role = role;
          }
          const response = await fetch("/api/getEmbedToken", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: Object.keys(bodyData).length
              ? JSON.stringify(bodyData)
              : null,
          });
          const data = await response.json();
          if (data?.reports?.length > 0) {
            setEmbedToken(null);
            setEmbedToken(data);
          } else {
            console.warn("No embed token found.");
          }
        } catch (error) {
          console.error("Could not fetch PowerBI report", error);
        }
      }
    },
    []
  );

  useEffect(() => {
    const getIdToken = async () => {
      try {
        const instanceToken = await instance.acquireTokenSilent({
          scopes: ["User.Read"],
          account: instance.getAllAccounts()[0],
        });
        if (instanceToken.idToken) {
          setIdToken(instanceToken.idToken);
          getEmbedToken(instanceToken.idToken, alternateOid, reportId, role);
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          console.log(
            "Silent token acquisition failed. Acquiring token using redirect"
          );
          instance.acquireTokenRedirect({
            scopes: [],
          });
        } else {
          console.error("Could not acquire token.", error);
        }
      }
    };

    if (instance && !idToken) {
      getIdToken();
    }
  }, [instance, getEmbedToken, idToken]);

  return { embedToken, getEmbedToken, idToken };
};

export default useEmbedToken;
