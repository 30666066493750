import { PowerBIEmbed } from "powerbi-client-react";
import { IEmbedToken } from "../auth/GetEmbedToken";
import { models } from "powerbi-client";

interface PBIEmbedProps {
  embedToken: IEmbedToken;
  reportId: string;
}

export const PBIEmbed: React.FC<PBIEmbedProps> = ({ embedToken, reportId }) => {
  return (
    <PowerBIEmbed
      key={embedToken.accessToken} // Change key to force re-render
      embedConfig={{
        type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
        id: reportId,
        embedUrl: embedToken.reports[0]?.embedUrl,
        accessToken: embedToken.accessToken,
        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
            bookmarks: {
              visible: false,
            },
          },
          // background: models.BackgroundType.Transparent,
        },
      }}
      eventHandlers={
        new Map([
          [
            "loaded",
            function () {
              console.log("Report loaded");
            },
          ],
          [
            "rendered",
            function () {
              console.log("Report rendered");
            },
          ],
          // TS ignore because of error in powerbi-client
          // @ts-ignore
          [
            "error",
            function (event) {
              console.log(event?.detail);
            },
          ],
          // ['visualClicked', () => console.log('visual clicked')],
          // ['pageChanged', (event) => console.log(event)],
        ])
      }
      cssClassName={"h-[90vh]"}
    />
  );
};
