import { useMsal } from "@azure/msal-react";

export interface Portal {
  title: string;
  link: string;
  permission: string;
  reportId?: string;
  role?: string;
}

export interface Portals {
  [key: string]: Portal;
}

const portals: Portals = {
  vendor: {
    title: "Vendor",
    link: "https://pelagia-production.operations.dynamics.com/?cmp=no01&mi=PurchTableListPage",
    permission: "VENDOR",
  },
  pickup: {
    title: "Pickup",
    link: "/pickup",
    permission: "PICKUP",
    reportId: process.env.REACT_APP_PICKUP_REPORT_ID,
    role: "partner-pickup-rls",
  },
  employee: {
    title: "Employee",
    link: "https://pelagiano.sharepoint.com/sites/Intranet",
    permission: "EMPLOYEE",
  },
  customer: {
    title: "Customer",
    link: "/customer",
    permission: "CUSTOMER",
    reportId: process.env.REACT_APP_CUSTOMER_REPORT_ID,
    role: "partner-rls",
  },
};

export function usePortals() {
  let permissions = ["PICKUP", "CUSTOMER"];

  const { accounts } = useMsal();

  const isEmployee =
    accounts[0]?.username.includes("@pelagia.com") ||
    accounts[0]?.username.includes("@solv.no") ||
    accounts[0]?.username.includes("@solv.as");

  if (isEmployee) {
    permissions.push("EMPLOYEE");
  }

  const filteredPortals: Portals = Object.keys(portals).reduce((acc, key) => {
    const portal = portals[key];
    if (permissions.includes(portal.permission)) {
      acc[key] = portal;
    }
    return acc;
  }, {} as Portals);

  return filteredPortals;
}
