import { SignInButton } from "../components/buttons/SignInButton";

export function Login() {
  return (
    <div className="flex flex-col space-y-5 py-10">
      <div className="text-center">
        <p className="text-2xl text-white mb-5 font-bold">
          Welcome to Pelagia AS partner portal
        </p>
      </div>
      <div className="text-center">
        <p className="text-l text-white">
          In the portal you will find information regarding your cooperation
          activities with Pelagia. <br></br> Please sign in to get access to
          your portal.
        </p>
      </div>
      <div className="text-center">
        <p className="text-l text-white">
          In case of emergency assignments, related to silage pick up,
          <br /> please dial our department Hordafor directly on this emergency
          number: <br />
          <a className="underline" href="tel:+47 902 76 706">
            +47 902 76 706
          </a>
        </p>
      </div>
      <div className="text-center">
        <p className="text-l text-white">
          For all other inquiries contact your Pelagia contact person directly.
        </p>
      </div>
      <div className="text-center">
        <SignInButton />
      </div>
    </div>
  );
}
