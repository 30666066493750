interface ButtonProps {
  link: string;
  children: React.ReactNode;
}

export const LinkButton = ({ link, children }: ButtonProps) => {
  return (
    <>
      <a href={link}>
        <button className="bg-transparent text-white hover:opacity-75 py-4 w-11/12 border rounded">
          {children}
        </button>
      </a>
    </>
  );
};
